<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="개정" class="cardClassDetailForm revisionLayout" topClass="topcolor-orange" v-if="popupParam.fireDetectionId">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div class="row">
                  <div class="col-1">
                    <c-text
                      :required="editable && isRevision"
                      :editable="editable && isRevision"
                      label="개정번호"
                      name="revisionNum"
                      v-model="data.revisionNum">
                    </c-text>
                  </div>
                  <div class="col-2">
                    <c-text
                      :editable="false"
                      label="개정일시"
                      name="regDtStr"
                      v-model="data.regDtStr">
                    </c-text>
                  </div>
                  <div class="col-1">
                    <c-text
                      :editable="false"
                      label="개정자"
                      name="regUserName"
                      v-model="data.regUserName">
                    </c-text>
                  </div>
                  <div class="col-4">
                    <c-moc 
                      :editable="editable"
                      :isSubmit="saveCallData"
                      :document.sync="data"
                      :documentId="data.fireDetectionId"
                      documentTitle="installLocation"
                      mocRelatedTaskCd="RT00000001"
                      label="MOC번호"
                      name="sopMocId"
                      v-model="data.sopMocId"
                    />
                  </div>
                  <div class="col-4">
                    <c-text
                      :required="editable && isRevision"
                      :editable="editable && isRevision"
                      label="개정사유"
                      name="revisionContent"
                      v-model="data.revisionContent">
                    </c-text>
                  </div>
                </div>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="화재탐지 및 경보설비 설치계획 상세정보" class="cardClassDetailForm">
            <template slot="card-select">
              <c-select
                :editable="!isRevision"
                v-if="popupParam.fireDetectionId"
                :comboItems="gridrev.data"
                type="custom"
                typetext="개정이력"
                itemText="revisionNum"
                itemValue="fireDetectionId"
                name="selectedfireDetectionId"
                label=""
                v-model="selectedfireDetectionId"
                @input="rowClickRev"
              ></c-select>
            </template>
            <template slot="card-button">
              <q-btn-group outline >
                <q-icon
                  name="help"
                  style="font-size:20px; margin-right:10px;"
                  class="custom-btn text-primary">
                  <q-tooltip anchor="bottom left" self="top left">
                    <div class="tooltipCustomTop">
                      {{ $language('(주)') }}
                    </div>
                    <div class="tooltipCustom">
                    {{ $language('① ｢소방시설 설치･유지 및 안전관리에 관한 법률 시행령｣ 별표 1 및 ｢위험물안전관리법 시행규칙｣ 별표 17에 따라 분말소화설비 등 다른 형태의 소화설비를 추가하여 기재합니다.') }}<br>
                    {{ $language('② 이 서식 대신 “소방시설등(기준･일람)산출표”를 사용할 수 있습니다') }}
                    </div>
                  </q-tooltip>
                </q-icon>
                <c-btn
                  v-show="editable && popupParam.fireDetectionId && !isRevision"
                  label="개정"
                  icon="restart_alt"
                  @btnClicked="SetRevision" />
                <c-btn
                  v-show="editable && popupParam.fireDetectionId && isRevision"
                  label="개정취소"
                  icon="restart_alt"
                  @btnClicked="CancelRevision" />
                <c-btn
                  v-if="editable && popupParam.fireDetectionId"
                  label="삭제"
                  icon="remove"
                  @btnClicked="deleteData" />
                <c-btn
                  v-if="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="mappingType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-3">
                <c-text
                  :required="true"
                  :editable="editable"
                  label="설치지역"
                  name="installLocation"
                  v-model="data.installLocation">
                </c-text>
              </div>
              <div class="col-3">
                <c-plant
                  :required="true"
                  :editable="editable"
                  type="edit"
                  name="plantCd"
                  v-model="data.plantCd">
                </c-plant>
              </div>
              <div class="col-3">
                <c-process
                  :editable="editable"
                  label="관련공정"
                  multiple="single"
                  name="processCd"
                  v-model="data.processCd">
                </c-process>
              </div>
              <div class="col-3">
                <c-dept
                  :editable="editable"
                  label="관리부서"
                  name="deptCd"
                  v-model="data.deptCd">
                </c-dept>
              </div>
              <div class="col-3">
                <c-text
                  :editable="editable"
                  label="단독경보형감지기"
                  name="singleAlarm"
                  v-model="data.singleAlarm">
                </c-text>
              </div>
              <div class="col-3">
                <c-text
                  :editable="editable"
                  label="비상경보설비"
                  name="emergencyAlarm"
                  v-model="data.emergencyAlarm">
                </c-text>
              </div>
              <div class="col-3">
                <c-text
                  :editable="editable"
                  label="시작경보기"
                  name="startAlarm"
                  v-model="data.startAlarm">
                </c-text>
              </div>
              <div class="col-3">
                <c-text
                  :editable="editable"
                  label="자동화재탐지설비"
                  name="autoDetection"
                  v-model="data.autoDetection">
                </c-text>
              </div>
              <div class="col-3">
                <c-text
                  :editable="editable"
                  label="비상방송설비"
                  name="emergencyBroadcast"
                  v-model="data.emergencyBroadcast">
                </c-text>
              </div>
              <div class="col-3">
                <c-text
                  :editable="editable"
                  label="자동화재속보설비"
                  name="autoBreakingnews"
                  v-model="data.autoBreakingnews">
                </c-text>
              </div>
              <div class="col-3">
                <c-text
                  :editable="editable"
                  label="통합감시시설"
                  name="integrationSystem"
                  v-model="data.integrationSystem">
                </c-text>
              </div>
              <div class="col-3">
                <c-text
                  :editable="editable"
                  label="누전경보기"
                  name="shortAlarm"
                  v-model="data.shortAlarm">
                </c-text>
              </div>
            </template>
          </c-card>
        </div>
      </div>
      <c-dialog :param="popupOptions"></c-dialog>
    </q-form>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';

export default {
  name: 'fire-detection-detail',

  props: {
    popupParam: {
      type: Object,
      default: () => ({
        fireDetectionId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      saveCallData: '',
      selectedfireDetectionId: null,
      saveUrl: transactionConfig.psi.lbe.firedetect.insert.url,
      mappingType: 'POST',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      revlistUrl: '',
      editable: true,
      isSave: false,
      isdelete: false,
      isRevision: false,
      data: {
        fireDetectionId: '',  // 화재탐지및경보설비 일련번호
        plantCd: '',  // 사업장코드
        installLocation: '',  // 설치지역
        singleAlarm: '',  // 단독경보형감지기
        emergencyAlarm: '',  // 비상경보설비
        startAlarm: '',  // 시작경보기
        autoDetection: '',  // 자동화재탐지설비
        emergencyBroadcast: '',  // 비상방송설비
        autoBreakingnews: '',  // 자동화재속보설비
        integrationSystem: '',  // 통합감시시설
        shortAlarm: '',  // 누전경보기
        processCd: '',
        deptCd: '',
        revisionNum: '',
        groupId: '',
        sysRevision: 1,
        revisionContent: '제정',
        regDtStr: '',
        regUserName: '',
        sopMocId: ''
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
      gridrev: {
        columns: [
          {
            name: 'revisionNum',
            field: 'revisionNum',
            label: '개정번호',
            align: 'center',
            style: 'width: 80px',
            sortable: true,
          },
          {
            name: 'revisionContent',
            field: 'revisionContent',
            label: '개정사유',
            align: 'left',
            sortable: true,
          },
          {
            name: 'regDtStr',
            field: 'regDtStr',
            label: '개정일시',
            style: 'width: 150px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'regUserName',
            field: 'regUserName',
            label: '개정자',
            align: 'center',
            style: 'width: 80px',
            sortable: true,
          },
        ],
        data: [],
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.psi.lbe.firedetect.get.url;
      this.insertUrl = transactionConfig.psi.lbe.firedetect.insert.url;
      this.updateUrl = transactionConfig.psi.lbe.firedetect.update.url;
      this.deleteUrl = transactionConfig.psi.lbe.firedetect.delete.url;
      this.revlistUrl = selectConfig.psi.lbe.firedetect.list.url + '/revs';
      this.getData();
    },
    getData() {
      this.isRevision = false;
      if (this.popupParam.fireDetectionId) {
        this.getDetail(this.popupParam.fireDetectionId, true);
        this.selectedfireDetectionId = this.popupParam.fireDetectionId
      }
    },
    getDetail(_fireDetectionId, _check) {
      this.$http.url = this.$format(this.detailUrl, _fireDetectionId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.data = _result.data;
        this.data.regDtStr = this.data.regDtStr.slice(0, 19)
        // 상세팝업 최초작성/최근수정 정보 노출
        this.$emit('setRegInfo', _result.data)
        this.isRevision = false;
        if (_check) {
          this.getRevList(_result.data.groupId);
        }
        if(_result.data.fireDetectionId == this.popupParam.fireDetectionId) {
          this.editable = this.$route.meta.editable;
        } else {
          this.editable = false;
        }
      },);
    },
    saveData() {
      if (this.popupParam.fireDetectionId) {
        this.saveUrl = this.updateUrl;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.mappingType = 'POST';
      }
      let saveMsg = '저장하시겠습니까?';  
      if (this.isRevision) {
        saveMsg = '현재버전을 개정하여 저장하시겠습니까?';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인', 
            message: saveMsg,
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId;
              this.data.chgUserId = this.$store.getters.user.userId;
              // 개정시
              if (this.isRevision) {
                this.saveUrl = this.insertUrl;
                this.mappingType = 'POST';
                this.data.sysRevision = parseInt(this.data.sysRevision) + 1;
              }
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    deleteData() {
      window.getApp.$emit('CONFIRM', {
        title: '확인', 
        message: '삭제하시겠습니까?',
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.fireDetectionId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            this.isdelete = !this.isdelete;
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.fireDetectionId = result.data.fireDetectionId
      this.saveCallData = uid();
      this.getData();
    },
    SetRevision() {
      this.$http.url = selectConfig.com.revision.getversion;
      this.$http.param = {revisionNum: this.data.revisionNum}
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.data.revisionNum = _result.data;
        this.data.revisionContent = '';
        this.data.regDtStr = '';
        this.data.regUserName = '';
        this.data.sopMocId = '';
        this.isRevision = true;
      });
    },
    CancelRevision() {
      this.isRevision = false;
      this.getData();
    },
    getRevList(_groupId) {
      this.$http.url = this.revlistUrl;
      this.$http.type = 'GET';
      this.$http.param = {groupId: _groupId};
      this.$http.request((_result) => {
        this.gridrev.data = _result.data;
      },);
    },
    rowClickRev(row) {
      if (row) {
        this.selectedfireDetectionId = row;
        if (this.selectedfireDetectionId != this.data.fireDetectionId) {
          this.getDetail(this.selectedfireDetectionId, false);
        }
      }
    },
  }
};
</script>
